function initNavBurger(){
	$(".burger-button").off('click').on("click", function(e){
		e.preventDefault();		
		if ($(".main-header").hasClass("open")){

			var len = $(".main-header .right-side  .menu-item").length;
			for(var i=len-1; i>=0; i--){
				var item = $(".main-header .right-side  .menu-item").eq(i);
				TweenLite.to(item, 0.3, {ease:Quad.easeOut, x: 100, opacity: 0,  delay: (len-i)*0.1+0.1, onComplete: function(){
				}});
			}



			TweenMax.to($(".main-header .right-side"), 0.3, {ease:Quad.easeIn, x: $(".main-header .right-side").outerWidth(),  delay: 0, onComplete: function(){
				$(".main-header").removeClass("open");
			}});
			
			$("html").removeClass("scroll-lock");
		}else{
			$("html").addClass("scroll-lock");
			$(".main-header").addClass("open");

			// resize();


			TweenMax.to($(".main-header .right-side"), 0, {ease:Linear.easeOut, x: $(".main-header .right-side").outerWidth(),  delay: 0, onComplete: function(){}});

			TweenMax.to($(".main-header .right-side"), 0.3, {ease:Quad.easeOut, x:0,  delay: 0, onComplete: function(){
			}});
			TweenMax.to($(".main-header .right-side #primary-menu"), 0.3, {ease:Quad.easeOut, y: 0,  delay: 0, onComplete: function(){
			}});
			var len = $(".main-header .right-side  .menu-item").length;
			for(var i=0; i<len; i++){
				var item = $(".main-header .right-side  .menu-item").eq(i);
				TweenMax.to(item, 0, 	{ease:Linear.easeOut, x: -100, opacity: 0, delay: 0, onComplete: function(){}});
				TweenMax.to(item, 0.3, {ease:Quad.easeOut, x: 0, opacity: 1,  delay: i*0.1+0.1, onComplete: function(){
				}});
				
			}
		}
	});
}
	
function initSearch(){

	var seactionHeight = ( $(window).width() > 1024 ) 
		? '230px' 
		: $(window).height();

	$(".search-bar .icon").off().on( "click", function ( e ) {
        e.preventDefault();
        $(this).toggleClass("toggled");
        if ($(this).hasClass("toggled")){
            TweenLite.to($(".popup-search"), 1, { ease: Power4.easeOut,
				height:seactionHeight,
				className:"+=visible",
				opacity:1,
			});
            $(".main-header").addClass("search");
			search();
        }
		else {
			$(".main-header").removeClass("search");
			TweenLite.to($(".popup-search"), 1, { ease: Power4.easeOut,
				height:'0',
				opacity:0, 
				onComplete: function(){
                	$(".popup-search").removeClass("visible");    
            	} 
			});
        }
		
		// Handle search bar close on mobile.
		$(".burger-button").on("click", function(e){
			$(".search-bar.mobile .icon").click();
		});

		// On esc press.
		$(document).on('keyup',function(evt) {
			if (evt.keyCode == 27) {
				if( $(".search-bar .icon").hasClass('toggled') && 
					$(".popup-search").hasClass('visible') ){
						$(".search-bar .icon").click();
				}
			}
		});


		function search(){
			$(".input-field .search").on("click", function(e){
				if( validateFormFields( $('.form-search' ) ) ) {
					$('.form-search').submit();
				} ;
			});
		}
    });

	$(".search-form .btn-submit").off('click').on("click", function(e){
		$(".input-field .search").trigger('click');
	});


	// Sanitze search query.
	var searchInput = $('input[name="s"]');
	var oldVal = searchInput.val();
	var newVal = oldVal.replace( '+', ' ' );
	searchInput.val(newVal);
}

function validateFormFields( el ){

	var requiredFields = $('.input-group.required', el);
	for(var i=0; i<requiredFields.length; i++){
		validate(requiredFields[i]);
	}

	/**
	 * Add input field validations here.
	 * 
	 * Checks for field type and adds
	 * an error to its parent if validation fails
	 */
	function validate( field ){
		/**
		 * Removes all errors.
		 */
		$(field).removeClass('error');


		// FILE 	
		if( $('.form-control', field).attr("type") == "file" ) {

			/**
			 * @pathField The relevant file path field for our ".real-file" upload field.
			 */
			var pathField = $('.btn-browse[data-target="' 
				+ $('.form-control', field).attr("data-target") + '"]');

			/**
			 * @maxFileSize Define max file upload size.
			 * 15 MB.
			 */
			var maxFileSize = 15000;


			// If no file has been attached.
			if ( $('.form-control', field).prop('files')[0] === undefined ){
				
				$(field).addClass('error');
				shakeElement( pathField );

			} else {  // If file has attached:

				// Check for its type.
				if ( $('.form-control', field).prop('files')[0].type != "application/pdf" ){
					
					$(field).addClass('error');
					shakeElement( pathField );
				}

				// Check for its size.
				if ( ( $('.form-control', field).prop('files')[0].size / 1024 ) > maxFileSize ){
					
					$(field).addClass('error');
					shakeElement( pathField );
				}

			}
		}
		
		// Mail 	
		if( $('.form-control', field).attr("type") == "email" ) {
			if (!validateEmail( $('.form-control', field).val() )){
				$(field).addClass('error');
				shakeElement($(field));
			}
		}
		if( $('.form-control', field).attr("name") == "name-and-surname" || $('.form-control', field).attr("name") == "name-surname"  ) {
			if (!validateName( $('.form-control', field).val() )){
				$(field).addClass('error');
				shakeElement($(field));
			}
		}

		// Checkbox 
		if( $('.form-control', field).attr("type") == "checkbox" ) {
			if ( !$('.form-control', field)[0].checked ){
				$(field).addClass('error');
				shakeElement($(field));
			}
		}

		// Check if basic input field is not empty.
		if ( !$.trim( $('.form-control', field).val() ).length ){
			$(field).addClass('error');
			shakeElement($(field));
		} 		
		
	}
	
	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}
	function validateName(name) {
		const re = /^[a-zA-Z\s]*$/;
		return re.test(name);
	}
	function validatePhone(txtPhone) {
		var filter = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
		return filter.test(String(txtPhone));
	}
	function shakeElement(elem){
		TweenMax.to(elem, 0, {x:0});
		TweenMax.to(elem, 0.07, {x:"+=10", yoyo:true, repeat:4});
		TweenMax.to(elem, 0.07, {x:"-=10", yoyo:true, repeat:3});
	} 

	/**
	 * Removes all errors on input state change.
	 */
	el.find(".form-control").change(function(e){
		requiredFields.removeClass('error');
	});

	/**
	 * If no errors - return true.
	 */
	if( $( '.input-group.error', el ).length == 0 ){
		return true;
	}	

	return false;

}

function clearForms(form) {
	$(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
	$(':checkbox, :radio').prop('checked', false);

	form.each(function(){
		this.reset();
	});
}

function initAjaxSubmissions( el ){
	var sendBtn = $('.btn-submit', el );
	sendBtn.off().on( "click", function ( e ) {
		e.preventDefault();
		e.preventDefault();
		e.stopImmediatePropagation();
		
		if( validateFormFields( el ) ) {
			el.submit();
		}

	});


	el.submit(function(e){

		e.preventDefault();

		data = {
			'action'	: el.attr('action'),
			'data' 		: el.serialize(),
		};


		var formData = new FormData(el[0]);
		formData.append( 'action', el.attr('action'));
		formData.append( 'data', el.serialize());


		$.ajax({
			url: ajax_object.ajax_url,
			type: 'POST',
			processData: false,
			contentType: false,
			data:formData,
			 success: function(data) {
				if( data ){
					TweenLite.to( el, 0.3, {
						ease:Power1.easeOut, 
						autoAlpha: 0,
						opacity:1,
						display:'none',
						 onComplete: function(){
							clearForms(el);
							var message = el.parent().find('.response-message');
							TweenLite.to(message, 0.4, {
								ease:Quad.easeOut, 
								opacity:1,
								autoAlpha: 1,
								display:'block',
								// className:"+=visible",  
							});
						}
					});
				}
			},
			error: function(data) { 
				console.log('error');
			}
		});
	});

	if( $( '.real-file', el ).length ){

		var target  = '',
			btnTexts = [];

		$( '.btn-browse', el ).each(function(i, obj) {
			btnTexts[ $(this).attr('data-target') ] = $(this).text();
		});


		el.find('.btn-browse').on('click', function(e){
			e.preventDefault();
			target = $(this).attr('data-target');
			btnText = $(this).text();
			$('.real-file[data-target="' + target + '"]')
				.trigger('click');
		});
			

		
		$(document).on('change', '.real-file', function(){
			if( $(this).val() != '' ){
				$('.btn-browse[data-target="' + target + '"]').text( $(this).val() ).addClass('active');
			} 
			else {
				$('.btn-browse[data-target="' + target + '"]').text( btnTexts[target] ).removeClass('active'); 
			}

		});

	}
}

function showFullscreenLoading( el = null ){
	if( $(".fullscreen-loader").length ){
		$(".fullscreen-loader").addClass("visible");
		TweenLite.to($(".fullscreen-loader .spinner-wrap"), 0, {ease:Quad.easeOut, rotation:0, scale: 1,  delay: 0});
		TweenLite.to($(".fullscreen-loader"), 0, {ease:Power1.easeIn, opacity:0,  delay: 0});
		TweenLite.to($(".fullscreen-loader"), 0.3, {ease:Quad.easeIn, opacity:1,  delay: 0});
	}
}
function hideFullscreenLoading( el = null ){
	if( $(".fullscreen-loader").length ){
		TweenLite.to($(".fullscreen-loader .spinner-wrap"), 1.1, {ease:Quad.easeIn, rotation:0, scale: 0,  delay: 0});
		TweenLite.to($(".fullscreen-loader"), 0.3, {ease:Quad.easeOut, opacity:0,  delay: 0.3, onComplete: function(){
			$(".fullscreen-loader").removeClass("visible");
		}});
	}
}

 /**
 * initDropdownBox
 *
 * Handles dropdowns boxes. 
 * Currently at homepage "Geography block" and Contacts page.
 *
 *
 * @param	container Jquery element which contains .box-item.
 * @return	void
 */
function initDropdownBox( container ){

	if( container.length > 0 ) {
       slideTab( container );
		function slideTab( container ) {

            var heights = [],
                targets = $( '.box-item', container );
            for ( var i = 0; i < targets.length; i++ ) {
                var hiddenItem = $('.box-item-hidden', $(targets[i]) );
                heights.push( hiddenItem.outerHeight() ); 
                TweenLite.to( hiddenItem, 0, {
                    height: 0, 
                    opacity: 0, 
                    delay: 0,
                    autoAlpha: 0,
                });
            }
			container.find('.box-item .box-item-top').off('click').on('click', function(e){
                var boxItem = $(this).parent();
				if ( boxItem.hasClass('active') ){
                    boxItem.removeClass('active');
                    TweenLite.to( 
                        $( '.box-item-hidden',boxItem ), 0.3, { 
                            ease:Linear.easeOut, 
                            height:0, 
                            opacity: 0, 
                            autoAlpha: 0,
                            delay: 0,
                        });
				} 
                else {
					$('.box-item').removeClass('active');
                    TweenLite.to( $('.box-item-hidden.active'), 0.3, { 
                        ease:Linear.easeOut, 
                        height: 0, 
                        opacity: 0, 
                        autoAlpha: 0, 
                        className:"-=active",  
                        delay: 0,
                    });

                    if(boxItem.hasClass('active')){
                        TweenLite.to( 
                            $( '.box-item-hidden', boxItem ), 0.3, { 
                                ease:Linear.easeOut, 
                                height: 0, 
                                opacity: 0, 
                                className:"-=active",
                                autoAlpha: 0,
                                delay: 0
                            });
                    } else { 
                        TweenLite.to( 
                            $( '.box-item-hidden', boxItem ), 0.3, { 
                                ease:Linear.easeOut, 
                                height: heights[ boxItem.index() ], 
                                opacity: 1, 
                                className:"+=active",
                                autoAlpha: 1,
                                delay: 0
                            });
                            boxItem.addClass('active');
                    }
                    
				}
                e.stopImmediatePropagation();
			});
		}
	}
}

 /**
 * initSubitemDropdownBox
 *
 * Helper function for initDropdownBox(). Adds auto height to parent element.
 * Currently at homepage "Geography block".
 *
 * TODO Merge initDropdownBox() and initSubitemDropdownBox in one function.
 *
 * @param	container Jquery element which contains .box-item.
 * @return	void
 */
function initSubitemDropdownBox( container ){

	if( container.length > 0 ) {
       slideTab( container );
		function slideTab( container ) {

            var heights = [],
                targets = $( '.box-item-subitem', container );
            for ( var i = 0; i < targets.length; i++ ) {
                var hiddenItem = $('.box-item-subitem-hidden', $(targets[i]) );
                heights.push( hiddenItem.outerHeight() ); 
                TweenLite.to( hiddenItem, 0, {
                    height: 0, 
                    opacity: 0, 
                    delay: 0,
                    autoAlpha: 0,
                });
            }
			container.find('.box-item-subitem .box-item-subitem-top').off('click').on('click', function(e){
                var boxItem = $(this).parent();
				if ( boxItem.hasClass('active') ){
                    boxItem.removeClass('active');
                    TweenLite.to( 
                        $( '.box-item-subitem-hidden',boxItem ), 0.3, { 
                            ease:Linear.easeOut, 
                            height:0, 
                            opacity: 0, 
                            autoAlpha: 0,
                            delay: 0,
                        });
				} 
                else {
					$('.box-item-subitem').removeClass('active');
                    TweenLite.to( $('.box-item-subitem-hidden.active'), 0.3, { 
                        ease:Linear.easeOut, 
                        height: 0, 
                        opacity: 0, 
                        autoAlpha: 0, 
                        className:"-=active",  
                        delay: 0,
                    });

                    if(boxItem.hasClass('active')){
                        TweenLite.to( 
                            $( '.box-item-subitem-hidden', boxItem ), 0.3, { 
                                ease:Linear.easeOut, 
                                height: 0, 
                                opacity: 0, 
                                className:"-=active",
                                autoAlpha: 0,
                                delay: 0
                            });
                    } else { 
					
                        TweenLite.to( 
							boxItem.parent(), 0.3, { 
							ease:Linear.easeOut, 
							height: 'auto', 
							opacity: 1,
							autoAlpha: 1,
							delay: 0
						});
                        TweenLite.to( 
						$( '.box-item-subitem-hidden', boxItem ), 0.3, { 
							ease:Linear.easeOut, 
							height: heights[ boxItem.index() ], 
							opacity: 1, 
							className:"+=active",
							autoAlpha: 1,
							delay: 0
						});
                            boxItem.addClass('active');
                    }
                    
				}
                e.stopImmediatePropagation();
			});
		}
	}
}

function backToTop(){
	$('.back-to-top').off('click').on('click', function(e){
		e.preventDefault();
		$('html, body').animate({scrollTop:0}, '500');
	});
}

function openCookies(){
	$('.call-cookies').off('click').on('click', function(e){
		e.preventDefault();
		var cookieconsent = initCookieConsent();
		cookieconsent.showSettings();
	});
}

$(document).ready(function(){

	// TweenLite.to( $("body"), 0.4, { ease: Power0.easeOut, opacity:1 } );

	initNavBurger();
	initSearch();
	backToTop();
	// initAjaxSubmissions( $('.form-newsletter') );
	openCookies();
});

$(window).on('load', function(){ 
	$('body').removeClass('preload');
});

module.exports = { 
	showFullscreenLoading,
    hideFullscreenLoading,
	initDropdownBox,
	initSubitemDropdownBox,
	initAjaxSubmissions,
};
var modalJS = require('./modal.js');
var mainJS = require('./main.js');

function initBenefitsSlider(){
    if( $('.swiper-benefits').length ){
        var settingsSlide = {
            slidesPerView: 1.2,
            spaceBetween: 25,
            initialSlide : 0,
            speed: 600,
            effect: 'slide',
            updateOnWindowResize:true,
            loop:true,
            centeredSlides: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                200: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 1.2,
                  centeredSlides: false,
                  spaceBetween: 25,
                },
            },
        }
        
        var swiperBenefits;
        if ($(window).width() < 768 ){
            swiperBenefits = new Swiper( ".swiper-benefits", settingsSlide );
        }

        $(window).resize(function(){
            if ($(window).width() <= 768 ){
                // if(typeof swiperBenefits != 'undefined'){
                //     // swiperBenefits.destroy(true,true);
                // }
            } 
            else {
                // swiperBenefits.destroy(true,true);
                swiperBenefits = new Swiper( '.swiper-benefits', settingsSlide );
                // swiperBenefits.update();
            }
        });
    }
}


function initEmployeeSlider(){
    var settingsFade = {
        slidesPerView: 1,
        spaceBetween: 0,
        watchSlidesProgress: true,
        initialSlide : 0,
        speed: 1000,
        effect: 'fade',
        observer:true,
        noSwiping: true,
        noSwipingClass: 'right-side',
        loop:false,
        pagination: {
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        on: {
            init: function () {
                var activeSlide  = $('.swiper-employee .swiper-slide').eq( this.activeIndex );
                var item =  $('img', activeSlide);
                TweenMax.to(item, 0.6,{ease:Linear.easeOut, right: 0, delay: 0, onComplete: function(){}});
            },
            slidePrevTransitionStart: function () {
                slideInOutImages( this );
            },
            slideNextTransitionStart: function () {
                slideInOutImages( this );
            },
        },
    }
    var settingsSlide = {
        slidesPerView: 1.2,
        spaceBetween: 25,
        initialSlide : 0,
        speed: 600,
        effect: 'slide',
        updateOnWindowResize:true,
        loop:false,
        centeredSlides: false,
        preloadImages: true,
        speed: 1000,
        touchRatio:0.1,
        resistanceRatio:0.1,
        slidesOffsetBefore:true,
        calculateHeight:true,
        // calculateHeight:true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        // breakpoints: {
        //     200: {
        //       slidesPerView: 1.2,
        //       spaceBetween: 25,
        //       centeredSlides: false,
        //       loop:true,
        //     },
        //     768: {
        //       slidesPerView: 1.2,
        //       spaceBetween: 0,
        //     //   effect:'slide',
        //       loop:true,
        //       centeredSlides: false,
        //       spaceBetween: 25,
        //     },
        // },
    }

    function slideInOutImages( swiper ){
        var activeSlide = $('.swiper-employee .swiper-slide'),
            nextSlide   = $('.swiper-employee .swiper-slide').eq( swiper.activeIndex );
            
            TweenMax.to( $('img', activeSlide), 1.8,{ease: Power4.easeOut, right:'-200%', delay: 0, onComplete: function(){}});
            TweenMax.to( $('img', nextSlide), 1.5,{ease: Power4.easeOut, right: 0, delay: 0.2, onComplete: function(){}});
        
    }


    var swiperEmployee;
    if ( $(window).width() > 768 ){
        swiperEmployee = new Swiper('.swiper-employee', settingsFade );
    } else {
        swiperEmployee = new Swiper('.swiper-employee', settingsSlide);
    }

    $(window).resize(function(){
        if ($(window).width() <= 768 ){
            swiperEmployee.destroy(true,true);
            swiperEmployee = new Swiper('.swiper-employee',settingsSlide);
            // swiperEmployee.params.effect = 'slide';         
            swiperEmployee.update();
        } 
        else {
            swiperEmployee = new Swiper('.swiper-employee',settingsFade);
            swiperEmployee.update();
        }
    });
}

function initModalYoutubeVideo(id){
	var player;
    
	TweenLite.to($(".video-foreground"), 0, {ease:Linear.easeOut, opacity:0, delay: 0, onComplete: function(){}});
  
    var cw = $(".modal-content").width();
    var ch = $(".modal-content").height();

window.YT.ready(function() {
	player = new YT.Player('YouTubeBackgroundVideoPlayer', {
		videoId: id, // YouTube Video ID
		width: cw,               // Player width (in px)
		height: ch,              // Player height (in px)
		playerVars: {
		  playlist: id,
			autoplay: 1,        // Auto-play the video on load
			autohide: 1,
			disablekb: 1, 
			controls: 1,        // Hide pause/play buttons in player
			showinfo: 0,        // Hide the video title
			modestbranding: 1,  // Hide the Youtube Logo
			loop: 1,            // Run the video in a loop
			fs: 0,              // Hide the full screen button
			autohide: 0,         // Hide video controls when playing
			rel: 0,
			enablejsapi: 1,
			playsinline: 1,
			inline: 1,
            origin: window.location
		},
		events: {
		  onReady: function(e) {
			  e.target.mute();
			  e.target.setPlaybackQuality('hd1080');
			  var dur = e.target.getDuration();
			  setTimeout(function(){
				  dur = e.target.getDuration();	
			  }, 1000);
			  setInterval(function (){
				  dur = e.target.getDuration();	
			  },2000);
			  setInterval(function (){
  
				  var ct = e.target.getCurrentTime();
				  if (dur-2.5<ct){
  
					  player.seekTo(1);
					  
				  }
			  },500);
			  player.playVideo();
			  
		  },
		  onStateChange: function(e) {
			if(e && e.data === 1){
				var videoHolder = $(".canvas-video-wrap")[0];
				if(videoHolder && videoHolder.id){
				  videoHolder.classList.remove('loading');
				}
			}else if(e && e.data === 0){
			  e.target.playVideo()
			}
			if (e.data==0){
					TweenLite.to($(".video-foreground"), 0.3, {ease:Linear.easeOut, opacity:0, delay: 0, onComplete: function(){}});
			}
			if (e.data==1){
					TweenLite.to($(".video-foreground"), 0.3, {ease:Linear.easeOut, opacity:1, delay: 0, onComplete: function(){}});
			}
  
		  }
		  
		}
	});
    $('.modal-close').on('click', function(e) {
        player.destroy();
    })
});

	function resizeYoutube(){
		var cw = $(".modal-content").width();
		var ch = $(".modal-content").height();
  
		var aspect = cw/ch;
		if (typeof _youtubeVideoAspect !== 'undefined') {
			aspect = _youtubeVideoAspect;
		}
        TweenLite.to($(".video-foreground"), 0, {ease:Linear.easeOut, scale: 1, delay: 0, onComplete: function(){}});		
		// if (cw/ch<aspect){
		// 	TweenLite.to($(".video-foreground"), 0, {ease:Linear.easeOut, scale: (ch*aspect)/cw+0.10, delay: 0, onComplete: function(){}});		
		// }else{
		// 	TweenLite.to($(".video-foreground"), 0, {ease:Linear.easeOut, scale: (cw/aspect)/ch+0.10, delay: 0, onComplete: function(){}});	
		// }
	}
	$(window).resize(function(){
		resizeYoutube();
	});
	$(document).ready(function(){
		resizeYoutube();
	});
}




var _youtubeVideoAspect = 1500/640;
function onYouTubeIframeAPIReady() {
    var player;
    if( $('.video-foreground').length ){
        var videoID = $('.video-foreground').attr('data-video');
        $("body").trigger("initModalYoutubeVideo",videoID);
    }
}

function initHeroVideo(){
    $(".btn-play-video").on("click", function(e){
        $('.main-header').hide();
        modalJS.initModal( $('.modal.video') );
        onYouTubeIframeAPIReady();
    });
    $("body").on("initModalYoutubeVideo", function(e,id){
        initModalYoutubeVideo(id);
    });
}


function loadMoreVacancies(){

    if( $('.vacancies-wrap').length ){
        var requestParams = { 
            currentPage : 0,
            searchQuery : ( window.location.search != '' ) ? window.location.search.replace("?s=", "") : ''
        };

        $('.vacancies-block .load-more').click(function(e){
            e.preventDefault();    
        
            var button = $(this),
                request_data = {
                    'action'    : 'vacancies',
                    'params'    : requestParams,
                };

            $.ajax({ 
                url : ajax_object.ajax_url,
                data : request_data,
                type : 'POST',
                beforeSend : function ( xhr ) {
                    // button.text('Loading...');
                },
                success : function( data ){
                    if( data ) {         
                        if( requestParams.currentPage ){
                            $('.vacancies-wrap').append(data);
                        } else {
                            $('.vacancies-wrap').html(data);
                        }
                        // hideFullscreenLoading();

                        var postCard = $('.vacancies-wrap .card-vacancy');
                        postCard.each(function( i ) {
                            TweenLite.to( $(this), 0.3, { 
                                ease:Quad.easeOut,
                                bottom: 0,
                                opacity:1,
                                delay: i*0.01,
                            });
                        });
                        
                        requestParams.currentPage++;
                    } else {
                        button.addClass('hidden'); 
                    }
                }
            });
        });

        firstLoad();
        function firstLoad(){
            $('.vacancies-block .load-more').trigger('click');
        }
    }
}

function initLottie(){
    
    var paramsHeroWhite = {
        container: document.querySelector('.lottie-career-white'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieHeroWhite
    }

    var animHeroWhite;
    animHeroWhite= lottie.loadAnimation(paramsHeroWhite);
}

$(document).ready(function(){
    initBenefitsSlider();
    initEmployeeSlider();
    initHeroVideo();
    loadMoreVacancies();
    initLottie();
    mainJS.initAjaxSubmissions( $('.form-newsletter') );
    // alert(mainJS.is_search());
});